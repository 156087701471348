body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Register.module.css */
.Register_container__2qKQi {
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    /* background-color: #f8f8f8; */
}

h2 {
    text-align: center;
    font-family: 'Lobster', cursive;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

input {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #f8f8f8;
    ;
}

button {
    padding: 8px 16px;
    border-radius: 4px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    cursor: pointer;
}
/* Login.module.css */
.Login_container__37w6g {
    width: 300px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f8f8f8;
}

h2 {
    text-align: center;
    font-family: 'Lobster', cursive;
}

.Login_logininfo__eKp42 {
    display: flex;
    flex-direction: column;
    align-items: center;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

input {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

button {
    padding: 8px 16px;
    border-radius: 4px;
    background-color: #0f1011;
    color: #fff;
    border: none;
    cursor: pointer;
}
.carousel-container {
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.carousel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  will-change: transform;
}

.carousel-slide {
  flex: 0 0 50%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #f0f0f0;
  cursor: pointer;
}
.carousel-slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.carousel-slide img.clicked {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
  /* Add a scale effect on click */
}

/* Lightbox */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
    cursor: pointer;
}

.lightbox-content {
  max-width: 80%;
  max-height: 80%;
  overflow: hidden;
}

.lightbox img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.lightbox .info {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
}
#root{
  background: black;
}
