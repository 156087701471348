.carousel-container {
  max-width: 800px;
  margin: 0 auto;
  overflow: hidden;
  position: relative;
}

.carousel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
  will-change: transform;
}

.carousel-slide {
  flex: 0 0 50%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #f0f0f0;
  cursor: pointer;
}
.carousel-slide img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.3s;
}

.carousel-slide img.clicked {
  transform: scale(0.8);
  /* Add a scale effect on click */
}

/* Lightbox */
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
    cursor: pointer;
}

.lightbox-content {
  max-width: 80%;
  max-height: 80%;
  overflow: hidden;
}

.lightbox img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.lightbox .info {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #fff;
}
#root{
  background: black;
}